import React, { useEffect, useState } from 'react';
import { Button, Box, FormControl, OutlinedInput, InputAdornment } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { styled } from "@mui/material/styles";
import SyncIcon from '@mui/icons-material/Sync';
import SearchIcon from '@mui/icons-material/Search';
import { useDebounce } from '../../Util/useDebounce';
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import ListOutlinedIcon from '@mui/icons-material/ListOutlined';
import MuiToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import AdsClickOutlinedIcon from '@mui/icons-material/AdsClickOutlined';
import AddLocationOutlinedIcon from '@mui/icons-material/AddLocationOutlined';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: `${theme.spacing(2)} ${theme.spacing(1)}`,
    display: 'flex',
    alignItems: 'center',
    gap: '20px'
  },
  icon: {
    color: theme.palette.divider,
  }
}));

const ToggleButton = styled(MuiToggleButton)({
  "&.Mui-selected, &.Mui-selected:hover": {
    backgroundColor: '#FFD707'
  }
});

const CoveragesSearch = (props) => {
  const classes = useStyles();
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 1000);

  useEffect(() => {
    props.onSearchChange(debouncedSearch);
  }, [debouncedSearch])

  return (
    <Box component="form" className={classes.root} noValidate autoComplete="off">
      {props.view === "list" && (
        <FormControl size="small">
          <OutlinedInput
            id="outlined-adornment-password"
            placeholder="Search for coverages"
            notched={false}
            color="primary"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon className={classes.icon} />
              </InputAdornment>
            }
          />
        </FormControl>
      )}

      <ToggleButtonGroup
        value={props.view}
        exclusive
        
        onChange={props.handleView}
        aria-label="coverages view"
      >
        <ToggleButton value="map" aria-label="map">
          <MapOutlinedIcon />
        </ToggleButton>
        <ToggleButton value="list" aria-label="list">
          <ListOutlinedIcon />
        </ToggleButton>
      </ToggleButtonGroup>

      <ToggleButtonGroup
        value={props.mapInteraction}
        exclusive
        onChange={props.handleInteraction}
        aria-label="map interaction"
      >
        <ToggleButton value="click" aria-label="click">
          <AdsClickOutlinedIcon />
        </ToggleButton>
        <ToggleButton value="pin" aria-label="pin">
          <AddLocationOutlinedIcon />
        </ToggleButton>
      </ToggleButtonGroup>

      <Button
        color="primary"
        variant="outlined"
        onClick={props.handleReload}
        disabled={props.refreshLoading}
        startIcon={<SyncIcon />}
      >
        Refresh
      </Button>
    </Box>
  );
};

export default CoveragesSearch;
